var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7ff27cf0756786e83320614dd8296e1ac4a5ee39"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { captureConsoleIntegration } from "@sentry/integrations";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration(),
    captureConsoleIntegration({
      levels: ["error"]
    })
  ],
  beforeSend(event) {
    // Exclude events from Linux OS
    if (event?.contexts?.os?.name === "Linux") {
      return null;
    }

    // Exclude events from headless Chrome
    const userAgent = event?.request?.headers?.["user-agent"] || "";
    if (userAgent.includes("HeadlessChrome")) {
      return null;
    }

    // Exclude events from known bots
    const botUserAgents = [
      "Googlebot",
      "Bingbot",
      "Slurp",
      "DuckDuckBot",
      "Baiduspider",
      "YandexBot",
      "Sogou",
      "Exabot",
      "facebot",
      "ia_archiver"
    ];
    const isBot = botUserAgents.some((bot) => userAgent.includes(bot));
    if (isBot) {
      return null;
    }

    return event;
  },
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.05
});
